/* In your main CSS file or index.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Custom styles for the slider dots (lines) */
.slick-line-dots {
    bottom: -25px; /* Position the dots below the content */
    display: flex !important;
    justify-content: center;
  }
  
  .slick-line-dots li {
    width: 30px; /* Width of each line */
    margin: 0 5px; /* Add some spacing between lines */
  }
  
  .slick-line-dots li button {
    width: 100%;
    height: 2px; /* Height of the line */
    background-color: gray; /* Default color for inactive lines */
    border: none;
  }
  
  .slick-line-dots li.slick-active button {
    background-color: black; /* Color for the active line */
  }
  